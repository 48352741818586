<template>
  <a-card :bordered="true">
    <page-header-wrapper :title="false"></page-header-wrapper>
    <a-form layout="inline">
      <a-form-item label="用户名">
        <a-input allowClear v-model="queryParam.username" placeholder="请输入"/>
      </a-form-item>
      <a-form-item label="状态">
        <a-select allowClear v-model="queryParam.status" style="min-width:100px" :default-value="0" placeholder="请选择">
          <a-select-option :value="1">启用</a-select-option>
          <a-select-option :value="2">禁用</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="$refs.table.refresh(true);onClearSelected()">查询</a-button>
      </a-form-item>
      <a-form-item>
        <a-button style="margin-left: 8px" @click="clearSearch()">重置</a-button>
      </a-form-item>
    </a-form>

    <div class="table-operator" align="right">
      <a-button v-action:add icon="plus" type="primary" @click="handleAdd()">新建</a-button>
      <a-dropdown v-if="selectedRowKeys.length > 0" v-action:del>
        <a-menu slot="overlay">
          <a-menu-item key="1" @click="moreDel">
            <a-icon type="delete"/>
            删除
          </a-menu-item>
        </a-menu>
        <a-button>
          批量操作
          <a-icon type="down"/>
        </a-button>
      </a-dropdown>
    </div>
    <div class="ant-alert ant-alert-info" style="margin-bottom: 16px;">
      <i class="anticon anticon-info-circle ant-alert-icon"></i> 已选择&nbsp;<a style="font-weight: 600">{{
        selectedRowKeys.length }}</a>项&nbsp;&nbsp;
      <a style="margin-left: 24px" @click="onClearSelected">清空</a>
    </div>
    <s-table
      ref="table"
      :columns="columns"
      :data="loadData"
      :rowSelection="rowSelection"
      :scroll="{ x: 800 }"
      rowKey="key"
      size="default"
    >
      <span slot="action" slot-scope="text, record">
        <a v-action:edit @click="handleEdit(record)">编辑</a>
        <a-divider v-action:state type="vertical"/>

        <a-dropdown>
          <a class="ant-dropdown-link">
            更多 <a-icon type="down"/>
          </a>
          <a-menu slot="overlay">
            <a-menu-item v-if="record.status==1" v-action:state>
              <a @click="handleStatus(record)">禁用</a>
            </a-menu-item>
            <a-menu-item v-if="record.status==2" v-action:state>
              <a @click="handleStatus(record)">启用</a>
            </a-menu-item>
            <a-menu-item v-if="record.adminId>1" v-action:del>
              <a-popconfirm title="确定删除吗?" @confirm="() => handleDel(record)">
                <a>删除</a>
              </a-popconfirm>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </s-table>
    <a-drawer
      :title="title"
      placement="right"
      :closable="false"
      :visible="visible"
      width="600"
      :get-container="false"
      @close="handleCancel"
    >
      <formCreate
        ref="createModal"
        :model="mdl"
      />
      <div
        :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
      >
        <a-button style="margin-right: 8px" @click="handleCancel">取消</a-button>
        <a-button type="primary" @click="handleOk">保存</a-button>
      </div>
    </a-drawer>
  </a-card>
</template>

<script>
  import { STable } from '@/components'
  import { create, del, FetchList, moreDel, update } from '@/api/admin'
  import { formatterTime } from '@/utils/util'
  import formCreate from './form'
  import pick from 'lodash.pick'

  export default {
    name: 'TableList',
    components: {
      STable,
      formCreate
    },
    data () {
      return {
        selectedRowKeys: [],
        selectedRows: [],
        visible: false,
        confirmLoading: false,
        loading: false,
        title: '',
        mdl: null,
        // 查询参数
        queryParam: {},
        // 表头
        columns: [
          {
            title: 'ID',
            dataIndex: 'adminId',
            sorter: true
          },
          {
            title: '用户名',
            dataIndex: 'username'
          },
          {
            title: '昵称',
            dataIndex: 'nickname'
          },
          {
            title: '状态',
            dataIndex: 'status',
            sorter: true,
            customRender: (val) => {
              return val === 1 ? '启用' : '禁用'
            }
          },
          {
            title: '创建时间',
            align: 'center',
            dataIndex: 'createTime',
            sorter: true,
            customRender: (val) => {
              return formatterTime(val)
            }
          }, {
            title: '操作',
            width: '150px',
            dataIndex: 'action',
            scopedSlots: { customRender: 'action' }
          }
        ],
        // 加载数据方法 必须为 Promise 对象
        loadData: parameter => {
          var searchParam = []

          searchParam = this.getCommonOperate(searchParam, ['=__status', 'like__username'])

          return FetchList(Object.assign(parameter, searchParam))
            .then(res => {
              return res.result
            })
        }
      }
    },
    computed: {
      rowSelection () {
        return {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange,
          getCheckboxProps: record => ({
            props: {
              disabled: record.adminId === 1
            }
          })
        }
      }
    },
    methods: {
      getCommonOperate (searchParam, fileds) {
        for (var key in this.queryParam) {
          var value = this.queryParam[key]
          value = value.toString().trim()
          if (typeof value !== 'number') {
            if (value === undefined || value === '' || value === null) {
              continue
            }
            value = value.toString().trim()
            if (value.length === 0) continue
          } else {
            if (value === 0) {
              continue
            }
          }
          for (var j = 0; j < fileds.length; j++) {
            var array = fileds[j].split('__')
            if (array[1] === key) {
              searchParam[fileds[j]] = value
            }
          }
        }
        return searchParam
      },

      handleAdd () {
        this.title = '新增'
        this.mdl = null
        this.visible = true
      },
      handleEdit (record) {
        console.log(record)
        this.title = '编辑'
        this.visible = true
        this.$nextTick(function () {
          const form = this.$refs.createModal.form
          const fields = ['adminId', 'username', 'nickname', 'status', 'type', 'roleIds', 'orgId', 'uid', 'regionId']
          const datafleld = record
          if (!Array.isArray(datafleld.roleIds)) {
            if (datafleld.roleIds.length > 0) {
              const tem = datafleld.roleIds.split(',')
              const arr = []
              for (let i = 0; i < tem.length; i++) {
                arr.push(Number(tem[i]))
              }
              datafleld.roleIds = arr
            } else {
              datafleld.roleIds = []
            }
          }
          if (datafleld.type === 2) {
            this.$refs.createModal.sekType()
          }
          form.setFieldsValue(pick(datafleld, fields))
        })
      },
      handleOk () {
        const self = this
        const form = this.$refs.createModal.form

        form.validateFields((errors, values) => {
          if (!errors) {
            if (values.adminId > 0) {
              // 修改 e.g.
              update(values)
                .then(res => {
                  if (res.code === 1) {
                    this.visible = false
                    this.confirmLoading = false
                    // 重置表单数据
                    form.resetFields()
                    // 刷新表格
                    self.$refs.table.refresh()
                    self.$message.info('修改成功')
                  } else {
                    self.$message.error(res.message)
                  }
                })
            } else {
              // 新增
              create(values)
                .then(res => {
                  if (res.code === 1) {
                    this.visible = false
                    this.confirmLoading = false
                    // 重置表单数据
                    form.resetFields()
                    // 刷新表格
                    self.$refs.table.refresh()
                    self.$message.info('新增成功')
                  } else {
                    self.$message.error(res.message)
                  }
                })
            }
          } else {
            this.confirmLoading = false
          }
        })
      },
      handleCancel () {
        this.visible = false
        const form = this.$refs.createModal.form
        form.resetFields() // 清理表单数据（可不做）
      },
      handleDel (record) {
        const self = this
        this.$confirm({
          title: '确定要删除吗？',
          content: '删除后将不可恢复，请谨慎操作！',
          okText: '确认',
          cancelText: '取消',
          onOk () {
            del(record)
              .then(res => {
                if (res.code === 1) {
                  // self.$refs.table.refresh(true)
                  self.$refs.table.refresh(true)
                  self.$message.info(res.message)
                } else {
                  self.$message.error(res.message)
                }
              })
          }

        })
      },
      onSelectChange (selectedRowKeys, selectedRows) {
        this.selectedRowKeys = selectedRowKeys
        this.selectedRows = selectedRows
      },
      moreDel () {
        const self = this
        const arr = []
        if (this.selectedRows.length > 0) {
          for (let i = 0; i < this.selectedRows.length; i++) {
            if (this.selectedRows[i].adminId > 1) {
              arr.push(this.selectedRows[i].adminId)
            }
          }
          moreDel({ 'adminIds': arr.join(',') })
            .then(res => {
              if (res.code === 1) {
                self.$refs.table.refresh()
                this.selectedRowKeys = []
                this.selectedRows = []
                self.$message.info(res.message)
              } else {
                self.$message.error(res.message)
              }
            })
        }
      },
      handleStatus (record) {
        const self = this
        this.$nextTick(function () {
          if (record.status === 1) {
            record.status = 2
          } else {
            record.status = 1
          }
          update(record)
            .then(res => {
              // 刷新表格
              self.$refs.table.refresh()
              self.$message.info('修改成功')
            })
        })
      },
      onClearSelected () {
        this.selectedRowKeys = []
        this.selectedRows = []
      },
      clearSearch () {
        this.queryParam = {}
        this.onClearSelected()
      }
    }
  }
</script>
